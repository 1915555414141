import axios from 'axios';
import { getAuth } from 'firebase/auth';

export const baseUrl = typeof window === 'undefined' || process.env.NODE_ENV === 'development'
  ? 'http://localhost:8006'
  : `//${window.location.hostname}/api`;

const api = axios.create({
  baseURL: baseUrl
});

api.interceptors.request.use(async config => {
  const auth = getAuth();
  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken();
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

export default api;

import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Transition } from '@headlessui/react';
import { useUser } from './UserContext';
import LanguageSwitcher from './LanguageSwitcher';


const LIST_290 = [
  'kleinhandelsbedrijf',
  'restaurant- of cafébedrijf',
  'afhaal- of besteldienst',
  'ambachtsbedrijf',
  'hotelbedrijf',
  'kampeerbedrijf',
  'ANWB-winkel',
  'Apotheek met bovenwoning',
  'Apotheker',
  'Autobandenbedrijf',
  'Autoglasspecialist',
  'Autoschadeherstelbedrijf',
  'Bandenbedrijf, met verkoop en reparatie',
  'Beauty-en schoonheidssalon',
  'Benzinestation, met bedieningsgebouw/verkoop',
  'Bloemenstal',
  'Boetiek in ziekenhuis',
  'Carglass',
  'Consumptiekiosk',
  'Dameskappersbedrijf',
  'Drukkerij',
  'Fietsenmaker',
  'Fotostudio',
  'Galerie',
  'Gamma',
  'Garage annex verkoop',
  'Geautomatiseerde autowasserette',
  'Grootwinkelbedrijf',
  'Horeca in voetbalstadion',
  'Jachthaven',
  'Kampeergrond',
  'Kantine',
  'Kapper',
  'Kiosk bij zwembad',
  'Koffiecounter in ziekenhuis',
  'Meubelshowroom',
  'Naaiatelier',
  'Opslagruimte in combinatie met winkel',
  'Praxis',
  'Pruikenmaker',
  'Ruimte bestemd voor detailhandel',
  'Schoonheidssalon',
  'Sleutel- en hakkenbar',
  'Smederij',
  'Stalling en opslagruimte',
  'Stomerij-winkel',
  'Supermarkt',
  'Tattoo shop',
  'Toeristenpension',
  'Toonzaal',
  'Uitgifte depot voor stomen en reinigen',
  'Verhuur van het recht tot exploitatie van buffet en kiosk',
  'Verpachting van een (cafe)bedrijf',
  'Wasserette',
  'Winkel',
  'Winkel-in-winkel',
  'Winterbergingsloods',
];

const LIST_230A = [
  'Achterzaal van een horecapand verhuurd voor het Golden Ten-spel',
  'Afhaaladres ten behoeve van krantenbezorgers',
  'Autorijschool met kantoor',
  'Autoverhuurbedrijf met reparatiewerkplaats en benzinepomp',
  'Bank(filiaal)',
  'Bed & Breakfast',
  'Bedrijf ter fabricage van speciale elektronische apparatuur',
  'Beautysalon',
  'Bioscoop',
  'Bol.com',
  'Bordeel',
  'Bowlingbaan',
  'Casino',
  'Clublokaal',
  'Cultureel jongerencentrum',
  'Dierenpension/opvang van dieren',
  'De Hypotheker',
  'Fabricage van speciale elektronische apparatuur',
  'Fietsenstalling zonder reparatiewerkplaats',
  'Fitnesscentrum',
  'Fysiotherapie(bedrijf)',
  'Garage(stalling)',
  'Groothandel',
  'Jachthaven',
  'Kantoor; bureauwerkzaamheden',
  'Kiosk in een zwembad',
  'Kleermaker zonder verkoop',
  'Koffiekamer in rouwcentrum',
  'Loketruimte Staatsloterij',
  'Loods',
  'Magazijn- en kantoorruimte',
  'Makelaardij',
  'Manege',
  'Manicure',
  'Massagesalon',
  'Meubelshowroom',
  'Motorcrossterrein',
  'Museum',
  'Non-profit instelling',
  'Ontmoetingsplaats',
  'Opslagruimte',
  'Orthopedisch bedrijf',
  'Pakhuis',
  'Passagebureau van een luchtvaartmaatschappij',
  'Pedicure en huidverzorger',
  'Pensionbedrijf',
  'Pizzabesteldienst',
  'Praktijkruimte voor de uitoefening van een (vrij) beroep',
  'Reisbureau',
  'Rijschool met kantoor, leslokaal en bar',
  'Rijwielstalling',
  'Rouwkamers zonder verkoop',
  'Ruimte voor de uitoefening van de makelaardij',
  'Sauna',
  'Seksbioscoop',
  'Seksclub',
  'Schoonheidssalon',
  'Schoonheidscentrum/solarium',
  'Short-stay',
  'Showroom',
  'Snookercentrum',
  'Speelautomatenhal',
  'Sportschool',
  'Sportterrein met kleedruimte en kantine',
  'Tandarts(bedrijf)',
  'Tattoo shop',
  'Toonzaal',
  'Uitvaartcentrum/rouwcentrum',
  'Uitzendbureau',
  'Verenigingsgebouw',
  'Verhuurbedrijf',
  'Verkoopruimte voor boeken en tijdschriften',
  'Videotheek',
  'Vrij beroep',
  'VVV-kantoor',
  'Zonnebank(bank)centrum',
  'Zonnecentrum',
  'Zorgcomplex',
  'Zorginstellingen',
  'Zwembad',
];


const liberalisatiegrens = {
  2024 : 879.66,
  2023 : 808.06,
  2022 : 763.47,
  2021 : 752.33,
  2020 : 737.14,
  2019 : 720.42,
  2018 : 710.68,
  2017 : 710.68,
  2016 : 710.68,
  2015 : 710.68,
  2014 : 699.48,
  2013 : 681.02,
  2012 : 664.66,
  2011 : 652.52,
  2010 : 647.53,
  2009 : 647.53,
  2008 : 631.73,
  2007 : 621.78,
  2006 : 615.01,
  2005 : 604.72,
  2004 : 597.54,
}

const formatAmount = (amount, language) => {
  return new Intl.NumberFormat(language, {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

const ExampleModal = ({ isOpen, onClose, title, examples }) => {
  const { t } = useTranslation();
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-40 overflow-y-auto" static={true} onClose={onClose}>
        <div className="flex items-center justify-center min-h-screen px-4 text-center bg-gray-900 bg-opacity-50">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="scale-95 opacity-0"
            enterTo="scale-100 opacity-100"
            leave="ease-in duration-200"
            leaveFrom="scale-100 opacity-100"
            leaveTo="scale-95 opacity-0"
          >
            <div className="relative bg-white rounded-lg shadow-xl min-w-96 mx-auto p-8">
              <h2 className="text-xl font-bold mb-4">{t(title)}</h2>
              <div className="max-h-80 overflow-y-auto">
                <ul className="list-disc list-inside columns-1 md:columns-2 gap-4">
                  {examples.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
              <button
                className="btn-main px-6 py-3 mt-6"
                onClick={onClose}
              >
                {t('general.close')}
              </button>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};



const OnboardingModal = ({ isOpen, onClose }) => {
  const { t, i18n } = useTranslation();
  const [stage, setStage] = useState(0);
  const [selectedType, setSelectedType] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [rentComparison, setRentComparison] = useState("");
  const [showModal290, setShowModal290] = useState(false);
  const [showModal230a, setShowModal230a] = useState(false);
  const { updateUserType } = useUser();

  const handleNext = (type) => {
    if (type) {
      setSelectedType(type); // Set the selected user type
      setStage('confirm'); // Move to the confirmation stage
    }
  };

  const handleConfirm = () => {
    if (selectedType) {
      updateUserType(selectedType); // Update the user type in context or via API
      onClose();
    }
  };

  const renderContent = () => {
    switch (stage) {
      case 0:
        return (
          <div className="flex flex-col items-center space-y-6">
            <p className='max-w-60 mb-14'>
              {t('onboardingModal.startExplanation')}
            </p>
            <button
              className="btn-main px-6 py-3 w-60"
              onClick={() => handleNext('business')}
            >
              {t('onboardingModal.helpingSomeone')}
            </button>
            <button
              className="btn-main px-6 py-3 w-60"
              onClick={() => setStage(1)}
            >
              {t('onboardingModal.needHelp')}
            </button>
          </div>
        );
      case 1: // choice between residential vs business
        return (
          <div className="flex flex-col items-center space-y-4">
            <p className='max-w-60 mb-14'>
              {t('onboardingModal.livingOrOffice')}
            </p>
            <button
              className="btn-main px-6 py-3 w-60"
              onClick={() => setStage(2)}
            >
              {t('onboardingModal.rentResidence')}
            </button>
            <button
              className="btn-main px-6 py-3 w-60"
              onClick={() => setStage(3)}
            >
              {t('onboardingModal.rentBusiness')}
            </button>
            <button
                className="btn-main px-6 py-3 bg-gray-400 hover:bg-gray-500"
                onClick={() => setStage(0)}
              >
                {t('onboardingModal.cancel')}
              </button>
          </div>
        );
        case 2: // choice between vrij / sociaal based on year and rent
        return (
          <div>

          
          <div className="flex flex-col items-start space-y-4">
              <p className='mr-4 mb-10'>{t('onboardingModal.liberalizationLimitInfo')}</p>
            <div className="flex items-center w-full">
              <p className='mr-4'>{t('onboardingModal.yearQuestion')}</p>
              <select
                className="form-select mt-1 block"
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                <option value="" disabled>{t('onboardingModal.selectYear')}</option>
                {Object.keys(liberalisatiegrens).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            {selectedYear && (
              <>
                <div className="flex items-center w-full">
                  <p className='mr-4'>
                    {t('onboardingModal.rentQuestion', { year: selectedYear, amount: formatAmount(liberalisatiegrens[selectedYear], i18n.language) })}
                  </p>
                  <select
                    className="form-select mt-1 block"
                    value={rentComparison}
                    onChange={(e) => {
                      const value = e.target.value;
                      setRentComparison(value);
                      setSelectedType(value === 'lower' ? 'particulier_sociaal' : 'particulier_vrij');
                    }}
                  >
                    <option value="" disabled>{t('onboardingModal.selectComparison')}</option>
                    <option value="lower">{t('onboardingModal.lower')}</option>
                    <option value="higher">{t('onboardingModal.higher')}</option>
                  </select>
                </div>
              </>
            )}
            </div>
            <div className="flex flex-col items-center space-y-4">
            {selectedYear && rentComparison && (
              <div className="mt-14">
                <p className='mb-4'>
                  {t('onboardingModal.result', { type: selectedType === 'particulier_sociaal' ? 'sociale sector' : 'vrije sector' })}
                </p>
                <button
                  className="btn-main px-6 py-3"
                  onClick={() => setStage('confirm')}
                >
                  {t('onboardingModal.proceed')}
                </button>
              </div>
            )}
            <button
              className="btn-main px-6 py-3 bg-gray-400 hover:bg-gray-500 mt-4"
              onClick={() => {
                setSelectedYear("")
                setRentComparison("");
                setStage(0)
              }
            }
            >
              {t('onboardingModal.cancel')}
            </button>
          </div>
          </div>
        );
        case 3: // choice between 230a / 290
        return (
          <div className="flex flex-col items-center space-y-4">
            <p className='max-w-60 mb-2'>
              {t('onboardingModal.officeInfo')}
            </p>
            <p className='max-w-60 mb-2'>
              {t('onboardingModal.officeInfo2')}
            </p>
            <button
              className="btn-main px-6 py-3 bg-gray-400 hover:bg-gray-500"
              onClick={() => setShowModal290(true)}
            >
              {t('onboardingModal.290examples')}
            </button>
            <p className='max-w-60 mb-2'>
              {t('onboardingModal.officeInfo3')}
            </p>
            <button
              className="btn-main mb-14 px-6 py-3 bg-gray-400 hover:bg-gray-500"
              onClick={() => setShowModal230a(true)}
            >
              {t('onboardingModal.230examples')}
            </button>
            <div className="relative">
              <button
                className="btn-main mt-14 px-6 py-3"
                onClick={() => handleNext('winkelruimte')}
              >
                {t('onboardingModal.rent290Space')}
              </button>
            </div>
            <div className="relative">
              <button
                className="btn-main px-6 py-3"
                onClick={() => handleNext('kantoorruimte')}
              >
                {t('onboardingModal.rent230aSpace')}
              </button>
            </div>
            <button
              className="btn-main px-6 py-3 bg-gray-400 hover:bg-gray-500"
              onClick={() => setStage(0)}
            >
              {t('onboardingModal.cancel')}
            </button>
          </div>
        );
      case 'confirm': // confirm
        return (
          <div className="flex flex-col items-center space-y-4">
            <p className='mb-14 max-w-60'>
               {t('onboardingModal.confirmation')}
            </p>
            <div className="flex space-x-4">
              <button
                className="btn-main px-6 py-3"
                onClick={handleConfirm}
              >
                {t('onboardingModal.confirmonboarding')}
              </button>
              <button
                className="btn-main px-6 py-3 bg-gray-400 hover:bg-gray-500"
                onClick={() => setStage(0)}
              >
                {t('onboardingModal.cancel')}
              </button>
            </div>
          </div>
        );
      default:
        return <p>Error!</p>;
    }
  };

  return (
    <Fragment>

      <Transition show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-30 overflow-y-auto" static={true} onClose={() => {}}>
          <div className="flex items-center justify-center min-h-screen px-4 text-center bg-gray-900 bg-opacity-50">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="scale-95 opacity-0"
              enterTo="scale-100 opacity-100"
              leave="ease-in duration-200"
              leaveFrom="scale-100 opacity-100"
              leaveTo="scale-95 opacity-0"
            >
              <div className="relative bg-white rounded-lg shadow-xl min-w-96 md:min-w-[800px] md:min-h-[450px] mx-auto p-8">
                <div className="flex justify-end mb-4">
                    <LanguageSwitcher />
                  </div>
                <div className="m-12">{renderContent()}</div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      
      <ExampleModal
      isOpen={showModal290}
      onClose={() => setShowModal290(false)}
      title="onboardingModal.290examples"
      examples={LIST_290}
      />
  
      <ExampleModal
      isOpen={showModal230a}
      onClose={() => setShowModal230a(false)}
      title="onboardingModal.230examples"
      examples={LIST_230A}
      />

    </Fragment>
  );
};

export default OnboardingModal;

import React, { useState } from 'react';
import { auth } from './Firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage(t('forgetPassword.successMessage'));
    } catch (error) {
      console.error('Reset Password Error:', error);
      setError(t('forgetPassword.errorMessage'));
    }
  };

  return (
    <div className="max-w-md mx-auto px-4 py-8">
      <form onSubmit={handleResetPassword} className="space-y-6">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={t('forgetPassword.instruction')}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white btn-main focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          {t('forgetPassword.button')}
        </button>
      </form>
      {message && <div className="mt-2 text-sm text-success">{message}</div>}
      {error && <div className="mt-2 text-sm text-error">{error}</div>}
    </div>
  );
};

export default ForgetPassword;

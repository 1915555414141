import React, { useMemo } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useTranslation } from 'react-i18next';

const PDFViewer = ({ document }) => {
    const { t } = useTranslation();

    const fileUrl = useMemo(() => {
        if (document?.arrayBuffer) {
            const fileBlob = new Blob([document.arrayBuffer], { type: document.MIMEtype });
            return URL.createObjectURL(fileBlob);
        }
    }, [document?.arrayBuffer, document?.MIMEtype]);

    if (!document) {
        return <div className="text-center text-gray-500">{t('chatbot.noDocument')}</div>;
    }

    const workerScriptUrl = "https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js";

    return (
        <div className="ph-no-capture border border-border rounded-lg p-2 h-[82vh] overflow-auto">
            <Worker workerUrl={workerScriptUrl}>
                <Viewer fileUrl={fileUrl} />
            </Worker>
        </div>
    );
};

export default React.memo(PDFViewer);

// ChatbotPage.js
import React, { useState, useEffect } from 'react';
import DocumentUpload, { DocumentItem } from './DocumentUpload';
import Chatbot from './Chatbot';
import PDFViewer from './PDFViewer';
import WordViewer from './WordViewer';
import { generateDocumentEmbeddings, isWordDocument, processWordDoc, processPdf, processPdfOcr } from './DocProcessing';
import { GlobalWorkerOptions } from 'pdfjs-dist/legacy/build/pdf';
import { getDocument } from 'pdfjs-dist/legacy/build/pdf';
import { useTranslation } from 'react-i18next';
import LawSelectionForm from './LawSelection';
import UserTypeToggle from './UserTypeToggle';
import { useUser } from './UserContext';
import UpgradeModal from './UpgradeModal';
import OnboardingModal from './OnboardingModal';

GlobalWorkerOptions.workerSrc = "https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js";




const ChatbotPage = () => {
  const { t } = useTranslation();

  // console.log(window.location.hostname)

  const [showUpgradeModal, setShowUpgradeModal] = useState(false); 
  const [showOnboardingModal, setShowOnboardingModal] = useState(false)
  const { userInfo, subscriptionInfo } = useUser();

  const [messages, setMessages] = useState(() => [
      {
          content: t('chatbot.startMessage1'),
          role: "assistant"
      },
      {
          content: t('chatbot.startMessage2'),
          role: "assistant"
      }
  ]);
  const [documents, setDocuments] = useState([]);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [selectedLaws, setSelectedLaws] = useState({
    burgerlijk_wetboek: true,
    besluit_kleine_herstellingen: false,
    huisvestingswet: false,
    wet_goed_verhuurderschap: false,
    woningwet: false,
    besluit_servicekosten: false,
    huisvesting_den_haag: false,
    user_documents: false,
    besluit_energieprestatievergoeding_huur : false,
    besluit_goed_verhuurderschap : false,
    besluit_huurprijzen_woonruimte : false,
    besluit_huurtoeslag : false,
    besluit_specifieke_groepen_tijdelijke_huurovereenkomst : false,
    gemeentewet : false,
    leegstandswet : false,
    uitvoeringswet_huurprijzen_woonruimte : false,
    wet_betaalbare_huur : false,
    wet_huurtoeslag : false,
    wet_overleg_huurders_verhuurders : false,
    uitvoeringsregeling_huurprijzen : false,
  });

  //openai model version
  const isDevelopmentOrTest = process.env.NODE_ENV === 'development' || window.location.hostname.includes("app-test.hurai.nl") ? true : false;
  const defaultModelVersion = '4o';
  const [modelVersion, setModelVersion] = useState(defaultModelVersion); 
  const [userTypeSelection, setUserTypeSelection] = useState('particulier_vrij');
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [lawSelectionMode, setLawSelectionMode] = useState('basic');


  // display an unload message when there are messages in the chat as the user is about to leave the page.
//   useEffect(() => {
//     const handleBeforeUnload = (event) => {
//         if (messages.length > 0) {
//             event.preventDefault();
//         }
//     };
//     window.addEventListener('beforeunload', handleBeforeUnload);
//     return () => {
//         window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
// }, [messages]);


  const closeOnboardingModal = () => {
    setShowOnboardingModal(false);  // Function to close the modal
  };


  useEffect(() => {
    // Set the visibility of the upgrade modal based on the user type, if user_type is unknown, up
    if (userInfo.user_type === 'unknown') {
      setShowOnboardingModal(true);
    } else {
      setShowOnboardingModal(false);
    }
    // console.log("userInfo.user_type: " + userInfo.user_type)
    // console.log("showOnboardingModal: " + showOnboardingModal)
  }, [userInfo, showOnboardingModal]);


  useEffect(() => {
    setTimeout(() => {
      const surveyDiv = document.querySelector('.survey-container');
      if (surveyDiv) {
        surveyDiv.classList.add('delayed-survey');
      }
    }, 10000);
  }, []);

  useEffect(() => {
    const hasProcessedDocuments = documents.some(doc => doc.isProcessed && !doc.hasFailed);
  
    setSelectedLaws(prevLaws => {
      const updatedLaws = { ...prevLaws };
  
      if (!prevLaws.user_documents && hasProcessedDocuments) {
        // If user_documents was not selected before and hasProcessedDocuments is true
        // Turn on user_documents and turn off everything else
        Object.keys(updatedLaws).forEach(law => {
          updatedLaws[law] = false; // Turn off all laws
        });
        updatedLaws.user_documents = true; // Turn on user_documents
        updatedLaws.besluit_kleine_herstellingen = true; 
      } else if (prevLaws.user_documents && !hasProcessedDocuments) {
        // If user_documents was selected and not hasProcessedDocuments
        updatedLaws.user_documents = false; // Deselect user_documents
  
        // If only user_documents was selected, turn on burgerlijk_wetboek, else do nothing
        const enabledLawsCount = Object.values(prevLaws).filter(isEnabled => isEnabled).length;
        if (enabledLawsCount === 1) { // Means only user_documents was on
          updatedLaws.burgerlijk_wetboek = true;
        }
      }
      return updatedLaws;
    });
  }, [documents]);
  

  const fileToArrayBuffer = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };


  const handleUpload = async (eventOrFiles) => {

    // console.log(subscriptionInfo)

    if (!subscriptionInfo || !subscriptionInfo.isActive) {
      setShowUpgradeModal(true);
      return;
    }

    const files = eventOrFiles.target ? eventOrFiles.target.files : eventOrFiles;
    const promises = Array.from(files).map(async (file) => {
      const arrayBuffer = await fileToArrayBuffer(file);
      const doc = {
        arrayBuffer: arrayBuffer,
        filename: file.name,
        MIMEtype: file.type,
        isProcessed: false,
        requiresOcr: false,
        chunks: []
      }
      return doc;
    });

    const documentArray = await Promise.all(promises);

    setDocuments(prevDocuments => [...prevDocuments, ...documentArray]);
    setCurrentDocument(documentArray[0]);
    documentArray.forEach(processDocument);
  };

  const processDocument = async (document) => {
    // console.log(`Processing ${document.filename}...`);

    const arrayBufferCopy = document.arrayBuffer.slice(0);

    try {
      // Assuming `document.arrayBuffer` contains the Array Buffer of the file
      let chunks;
      if (isWordDocument(document.filename)) {
        // Process Word document from Array Buffer
        chunks = await processWordDoc(arrayBufferCopy);
      } else {
        // Process PDF document from Array Buffer
        // Here, you might directly pass the Array Buffer to your PDF processing function
        const pdfDoc = await getDocument({ data: arrayBufferCopy }).promise;
        chunks = await processPdf(pdfDoc);
        if (chunks.length === 0) {
          // Handling OCR or other processing as needed
          chunks = await processPdfOcr(pdfDoc);
        }
      }
      // console.log(chunks);

      const embeddings = await generateDocumentEmbeddings(chunks);
      // console.log(embeddings);

      // Zip chunks and embeddings together
      const chunkData = chunks.map((chunk, index) => ({
        text: chunk,
        embedding: embeddings[index]?.embedding || [],
        id: `chunk-${index}-${document.filename}`,
        metadata: { 'source': document.filename }
      }));

      // Update document processing status and chunks
      setDocuments(prevDocuments => prevDocuments.map(doc => {
        if (doc.filename === document.filename) {
          return { ...doc, isProcessed: true, chunks: chunkData, hasFailed: !(chunks.length > 0 && embeddings.length > 0) };
        }
        return doc;
      }));

      if (chunks.length === 0 || embeddings.length === 0) {
        console.log(`${document.filename} failed to process`);
      } else {
        console.log(`${document.filename} processed successfully`);
      }
    } catch (error) {
      console.error(`Error processing ${document.filename}:`, error);
      // Handle error, e.g., by marking the document as failed to process
      setDocuments(prevDocuments => prevDocuments.map(doc => {
        if (doc.filename === document.filename) {
          return { ...doc, isProcessed: true, hasFailed: true };
        }
        return doc;
      }));
    }
  };

  const retryDocumentProcessing = (document) => {
    setDocuments(prevDocuments => prevDocuments.map(doc => {
      if (doc.filename === document.filename) {
        return { ...doc, hasFailed: false, isProcessed: false };
      }
      return doc;
    }));
    processDocument(document);
  };

  const selectDocument = (file) => {
    setCurrentDocument(file);
  };

  const removeDocument = (docToRemove) => {
    setDocuments(prevDocuments => prevDocuments.filter(doc => doc.filename !== docToRemove.filename));
    if (currentDocument.filename === docToRemove.filename) {
      setCurrentDocument(documents.length > 1 ? documents.find(doc => doc.filename !== docToRemove.filename) : null);
    }
  };

  const renderDocumentViewer = (currentDocument) => {
    if (!currentDocument) {
      return (
        renderFileUpload()
      );
    }

    const fileType = currentDocument.filename.split('.').pop().toLowerCase();
    switch (fileType) {
      case 'pdf':
        return <PDFViewer document={currentDocument} />;
      case 'docx':
        return <WordViewer document={currentDocument} />;
      default:
        return <div className="text-center text-gray-500">{t('chatbot.unsupportedFormat')}</div>;
    }
  };

  const onDragOver = (e) => {
    e.preventDefault(); // Prevent default browser behavior
  };

  const onDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    // Filter out unsupported file types
    const supportedFiles = files.filter(file => file.name.endsWith('.pdf') || file.name.endsWith('.docx'));
    if (supportedFiles.length > 0) {
      handleUpload(supportedFiles); // Proceed only with supported files
    } else {
      alert(t('chatbot.unsupportedFormat'));
    }
  };

  // Render the file upload interface prominently if no documents have been uploaded
  const renderFileUpload = () => {
    return (
      <div className="col-span-full"
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        <div className="mt-0 flex justify-center min-h-[82vh] rounded-lg border border-dashed border-gray-900/25 p-3">
          <div className="text-center">
            <div className="mt-[34vh] text-sm leading-6 text-gray-600">
              <div>
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md font-semibold text-copy-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-600 focus-within:ring-offset-2 hover:text-copy-400"
                >
                  {t('chatbot.uploadInstructions', { returnObjects: true }).map((instruction, index) => (
                    <div key={index}>{instruction}</div>
                  ))}
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    accept=".pdf, .docx"
                    onChange={(e) => handleUpload(e.target.files)}
                  />
                </label>
              </div>
            </div>
            <p className="text-xs leading-5 text-gray-600">{t('chatbot.dropFileInstructions')}</p>
          </div>
        </div>
      </div>
    );
  };

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };


  return (
    <div className="container mx-auto p-4 survey-container">

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
        {documents.map((doc, index) => (
          <DocumentItem
            key={doc.filename}
            doc={doc}
            isSelected={currentDocument.filename === doc.filename}
            onSelectDocument={selectDocument}
            onRemoveDocument={removeDocument}
            onRetryProcessing={() => retryDocumentProcessing(doc)}
          />
        ))}
        {documents.length > 0 && (
          <div className="flex justify-start">
            <DocumentUpload onUpload={handleUpload} />
          </div>
        )}
      </div>
      <div>
        <div className="sm:flex sm:space-x-2 md:space-x-4">
        <div className="block md:hidden mb-4">
            <button
              onClick={toggleOptions}
              className="px-4 py-2 bg-primary-600 hover:bg-primary-500 text-white text-sm rounded-md w-full"
            >
              {isOptionsOpen ? t('chatbot.hideOptions') : t('chatbot.showOptions')}
            </button>
          </div>
          <div className={`${isOptionsOpen ? 'block' : 'hidden'} md:block md:min-w-1/10`}>
            <LawSelectionForm
              selectedLaws={selectedLaws}
              setSelectedLaws={setSelectedLaws}
              disableUserDocumentsToggle={documents.length === 0}
              setModelVersion={setModelVersion}
              mode={lawSelectionMode}
              onModeChange={setLawSelectionMode}
            />
            {userInfo.user_type === 'business' && lawSelectionMode !== 'online' && (

              <UserTypeToggle
                userType={userTypeSelection}
                setUserType={setUserTypeSelection}
              />
            )}
          </div>
          <div className="md:w-1/2 ">
            <Chatbot
              messages={messages}
              setMessages={setMessages}
              documents={documents.filter(doc => !doc.hasFailed)}
              selectedLaws={selectedLaws}
              modelVersion={modelVersion}
              user_type={userInfo.user_type === 'business' ? userTypeSelection : userInfo.user_type}
            />
          </div>
          <div className="md:w-1/2">
            {renderDocumentViewer(currentDocument)}
          </div>
        </div>
      </div>

      {showUpgradeModal && (
        <UpgradeModal
          onClose={() => setShowUpgradeModal(false)}
          upgradeMessage={t('profile.uploadSubscriptionRequired')}
        />
      )}
      <OnboardingModal isOpen={showOnboardingModal} onClose={closeOnboardingModal}/>
      </div>
  );
};

export default ChatbotPage;

import React, { useEffect, useState } from 'react';
import mammoth from 'mammoth/mammoth.browser';
import { useTranslation } from 'react-i18next'; 

function WordViewer({ document }) {
    const { t } = useTranslation();
    const [htmlContent, setHtmlContent] = useState(null);

    useEffect(() => {
        if (document) {
            (async () => {
                try {
                    // Directly use the ArrayBuffer from the document object
                    const result = await mammoth.convertToHtml({ arrayBuffer: document.arrayBuffer });
                    setHtmlContent(result.value);
                } catch (error) {
                    console.error("Error converting document to HTML:", error);
                }
            })();
        }
    }, [document]);

    if (!document) {
        return <div className="text-center text-gray-500">{t('chatbot.noDocument')}</div>;
    }

    return (
        <div className="ph-no-capture border border-border rounded-lg p-4 h-[82vh] overflow-auto">
            <div className="prose max-w-none">
                <div
                    className="word-document bg-white p-8 shadow-lg"
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                />
            </div>
        </div>
    );
}

export default WordViewer;

import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import huurcheckVideo from './assets/huurcheck.mp4';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

const RentalCheckPage = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white sm:py-2">
      <div className="mx-auto max-w-7xl px-6">
        
        {/* Video and description section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 items-center">
          {/* Description */}
          <div className="col-span-2 text-left">
            <h2 className="text-base font-semibold leading-7 text-primary-900">{t('rentalCheck.title')}</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-primary-900 sm:text-4xl">
              {t('rentalCheck.heading')}
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-700">
              {t('rentalCheck.description')}
            </p>
          </div>

          {/* Video */}
          <div className="col-span-1 text-center">
            <video className="w-full max-w-sm rounded-lg shadow-lg" autoPlay loop muted>
              <source src={huurcheckVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        {/* Benefits section */}
        <div className="mt-16 p-10 bg-blue-100 rounded-lg shadow-md">
          <h3 className="text-2xl font-extrabold text-primary-900 text-center">{t('rentalCheck.benefitsTitle')}</h3>
          <ol className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-8 text-lg text-gray-900">
            <li key="benefit1" className="flex flex-col items-center">
              <CheckCircleIcon className="h-10 w-10 text-green-600 mb-4" />
              <strong>{t('rentalCheck.benefit1.title')}</strong>
              <p className="mt-2 text-center">{t('rentalCheck.benefit1.description')}</p>
            </li>
            <li key="benefit2" className="flex flex-col items-center">
              <CheckCircleIcon className="h-10 w-10 text-green-600 mb-4" />
              <strong>{t('rentalCheck.benefit2.title')}</strong>
              <p className="mt-2 text-center">{t('rentalCheck.benefit2.description')}</p>
            </li>
            <li key="benefit3" className="flex flex-col items-center">
              <CheckCircleIcon className="h-10 w-10 text-green-600 mb-4" />
              <strong>{t('rentalCheck.benefit3.title')}</strong>
              <p className="mt-2 text-center">{t('rentalCheck.benefit3.description')}</p>
            </li>
          </ol>
        </div>

        {/* New section before the CTA */}
        <div className="mt-10 text-center">
          <p className="text-lg font-semibold text-primary-900">
            <Trans
              i18nKey="rentalCheck.sendEmailText"
              values={{ email: "huurcheck@hurai.nl" }}
              components={{
                a: <a href="mailto:huurcheck@hurai.nl" className="text-primary-600 hover:text-blue-800" />
              }}
            />*
          </p>
          <a
            href="mailto:huurcheck@hurai.nl"
            className="mt-6 inline-block px-6 py-3 bg-primary-600 text-white text-bold rounded-md hover:bg-primary-700"
          >
            {t('rentalCheck.sendEmailButton')}
          </a>
        </div>

        {/* Disclaimer section */}
        <div className="mt-8 text-center text-sm text-gray-600">
          *{t('rentalCheck.disclaimer', { disclaimer: 'Gratis voor gebruikers van Harvey. Zie "Upgrade" voor onze plannen.' })}
        </div>

      </div>
    </div>
  );
};

export default RentalCheckPage;

import React, { useEffect, useState, useCallback } from 'react';
import { auth } from './Firebase';
import { signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from './Api';
import { useUser } from './UserContext';

const Profile = () => {
  const { user, userInfo, subscriptionInfo, setUser, setUserInfo, setSubscriptionInfo } = useUser();
  const [editMode, setEditMode] = useState(false);
  const [tempUserInfo, setTempUserInfo] = useState({ phone: '', organization: '', functionTitle: '', accept_contact: false, user_type: 'unknown' });
  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchUserData = useCallback(async () => {
    try {
      const response = await api.get('/user');
      setUserInfo(response.data);
      setTempUserInfo({
        phone: response.data.phone,
        organization: response.data.organization,
        functionTitle: response.data.functionTitle,
        accept_contact: response.data.accept_contact
      });
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, [setUserInfo]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const subscriptionResponse = await api.get('/user/subscription');
        setSubscriptionInfo(subscriptionResponse.data);
      } catch (error) {
        console.error('Error fetching subscription data:', error);
      }
    };

    fetchSubscription();
  }, [setSubscriptionInfo, user]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      navigate('/');
    } catch (error) {
      console.error('Logout Error:', error);
    }
  };

  const handleSave = async () => {
    try {
      await api.patch('/user', tempUserInfo);
      setEditMode(false);
      fetchUserData();
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleCancel = () => {
    setTempUserInfo(userInfo); // Revert changes made during edit mode
    setEditMode(false);
  };

  const navigateToPlans = () => {
    navigate('/upgrade');
  };

  const handleDeleteAccount = async () => {

    const confirmedEmail = window.prompt(t('profile.confirmDeletionPrompt'));

    // Check if the entered email matches the user's email
    if (confirmedEmail === user.email) {
      try {
        const response = await api.delete('/user');
        alert(response.data.message); // Alert the user about the deletion
        handleLogout(); // Log out the user
      } catch (error) {
        console.error('Error deleting account:', error);
        alert(t('profile.deletionError')); // Inform the user of the error
      }
    } else if (confirmedEmail) {
      alert(t('profile.confirmDeletionError')); // Email mismatch case
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="max-w-2xl w-full mx-auto bg-white p-8 border border-copy-300 rounded-lg shadow-sm">
        <h3 className="text-center text-2xl font-extrabold text-copy-900 mb-4">{t('general.profile')}</h3>
        <div className="mb-4">
          <p className="text-copy-600"><strong>{t('general.email')}:</strong> {user.email}</p>
          {editMode ? (
            <>
              <div className="my-4">
                <label htmlFor="phone" className="block text-sm font-medium text-copy-700">{t('general.phone')}</label>
                <input
                  id="phone"
                  type="tel"
                  value={tempUserInfo.phone}
                  onChange={(e) => setTempUserInfo({ ...tempUserInfo, phone: e.target.value })}
                  className="btn-mainmt-1 p-2 w-full rounded-md border-copy-300 shadow-sm focus:border-primary-500 focus:ring focus:ring-primary-500 focus:ring-opacity-50"
                />
              </div>
              <div className="my-4">
                <label htmlFor="organization" className="block text-sm font-medium text-copy-700">{t('signup.organization')}</label>
                <input
                  id="organization"
                  type="text"
                  value={tempUserInfo.organization}
                  onChange={(e) => setTempUserInfo({ ...tempUserInfo, organization: e.target.value })}
                  className="btn-mainmt-1 p-2 w-full rounded-md border-copy-300 shadow-sm focus:border-primary-500 focus:ring focus:ring-primary-500 focus:ring-opacity-50"
                />
              </div>
              <div className="my-4">
                <label htmlFor="functionTitle" className="block text-sm font-medium text-copy-700">{t('signup.functionTitle')}</label>
                <input
                  id="functionTitle"
                  type="text"
                  value={tempUserInfo.functionTitle}
                  onChange={(e) => setTempUserInfo({ ...tempUserInfo, functionTitle: e.target.value })}
                  className="btn-mainmt-1 p-2 w-full rounded-md border-copy-300 shadow-sm focus:border-primary-500 focus:ring focus:ring-primary-500 focus:ring-opacity-50"
                />
              </div>
              <div className="flex items-center mb-4">
                <input
                  id="accept_contact"
                  type="checkbox"
                  checked={tempUserInfo.accept_contact}
                  onChange={(e) => setTempUserInfo({ ...tempUserInfo, accept_contact: e.target.checked })}
                  className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-copy-300 rounded"
                />
                <label htmlFor="accept_contact" className="ml-2 block text-sm text-copy-900">
                  {t('signup.acceptContact')}
                </label>
              </div>
              <div className="flex justify-between">
                <button onClick={handleSave} className="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                  {t('general.save')}
                </button>
                <button onClick={handleCancel} className="py-2 px-4 border border-copy-300 text-sm font-medium rounded-md text-copy-700 bg-white hover:bg-copy-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                  {t('general.cancel')}
                </button>
              </div>
            </>
          ) : (
            <div>
              <div>
                <p className="text-copy-600"><strong>{t('general.phone')}:</strong> {userInfo.phone}</p>
                <p className="text-copy-600"><strong>{t('signup.organization')}:</strong> {userInfo.organization}</p>
                <p className="text-copy-600"><strong>{t('signup.functionTitle')}:</strong> {userInfo.functionTitle}</p>
                <p className="text-copy-600"><strong>{t('signup.acceptContact')}:</strong> {userInfo.accept_contact ? t('general.yes') : t('general.no')}</p>
              </div>
              <div>
                <button onClick={() => setEditMode(true)} className="py-2 px-4 mt-2 border border-transparent text-sm font-medium rounded-md text-copy-700 bg-primary-100 hover:bg-copy-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                  {t('general.edit')}
                </button>
              </div>
            </div>
          )}
        </div>
        <div className='flex items-center'>
          <button
            onClick={handleLogout}
            className="mr-4  py-2 px-4 border border-transparent text-sm font-medium rounded-md text-red-600 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-error"
          >
            {t('general.logout')}
          </button>
          <button
            onClick={handleDeleteAccount}
            className="mr-4 ml-4  py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-error"
          >
            {t('profile.deleteAccount')}
          </button>
        </div>
      </div>

      <div className="max-w-2xl w-full mx-auto mt-8 bg-white p-8 border border-copy-300 rounded-lg shadow-sm columns-2">
        <div className='w-full'>
          <h4 className="text-xl font-bold text-copy-900 mb-4">Current Subscription</h4>
          {subscriptionInfo ? (
            <div className="mb-4">
              <p className="text-copy-600"><strong>Start Date:</strong> {new Date(subscriptionInfo.StartDate).toLocaleDateString()}</p>
              <p className="text-copy-600"><strong>End Date:</strong> {new Date(subscriptionInfo.EndDate).toLocaleDateString()}</p>
            </div>
          ) : <p>No active subscription.</p>}
        </div>
        <div>
          <button
            onClick={navigateToPlans}
            className="flex justify-center px-6 py-3 bg-secondary-700 text-white text-bold rounded-md hover:bg-secondary-600 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2"
          >
            Upgrade
          </button>

        </div>
      </div>
    </div>
  );
};

export default Profile;

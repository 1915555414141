import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const UpgradeModal = ({ onClose, upgradeMessage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50 p-4">
      <div className="relative bg-white rounded-lg shadow-xl min-w-96 mx-auto p-8">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-3xl font-semibold text-gray-800 mb-6 m-12 flex justify-center">{t('general.upgradeAccount')}</h2>
        <p className="text-gray-600 mb-8 m-12">{upgradeMessage}</p>
        <div className="flex justify-center space-x-4">
          <button
            onClick={() => navigate('/upgrade')}
            className="px-6 py-3 bg-secondary-700 text-white text-bold rounded-md hover:bg-secondary-600 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2"
          >
            Upgrade
          </button>
        </div>
      </div>
    </div>
  );
};

export default UpgradeModal;

import React, { useState, useEffect , useRef} from 'react';
import i18n from './i18n';

function LanguageSwitcher() {
    const [showDropdown, setShowDropdown] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleLanguageChange = () => {
            setCurrentLanguage(i18n.language); // Update current language state on language change
        };

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []);

    useEffect(() => {
        // Function to detect click outside and disappear dropdownmenu
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };
        if (showDropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDropdown]);

    const languages = [
        {
            code: 'en',
            name: 'English',
            flag: (<svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-gb" viewBox="0 0 640 480" width="30" height="20">
                <path fill="#012169" d="M0 0h640v480H0z" />
                <path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0z" />
                <path fill="#C8102E" d="m424 281 216 159v40L369 281zm-184 20 6 35L54 480H0zM640 0v3L391 191l2-44L590 0zM0 0l239 176h-60L0 42z" />
                <path fill="#FFF" d="M241 0v480h160V0zM0 160v160h640V160z" />
                <path fill="#C8102E" d="M0 193v96h640v-96zM273 0v480h96V0z" />
            </svg>)
        },
        {
            code: 'nl',
            name: 'Nederlands',
            flag: (<svg xmlns="http://www.w3.org/2000/svg" id="flag-icons-nl" viewBox="0 0 640 480" width="26" height="18">
                <path fill="#21468b" d="M0 0h640v480H0z" />
                <path fill="#fff" d="M0 0h640v320H0z" />
                <path fill="#ae1c28" d="M0 0h640v160H0z" />
            </svg>)
        }
    ];

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setShowDropdown(false); // Close dropdown after selection
    };

    const getFlagByCode = (code) => {
        const language = languages.find((lng) => lng.code === code);
        return language ? language.flag : null;
    };

    return (
        <div className="relative" ref={dropdownRef}>
            <button onClick={() => setShowDropdown(!showDropdown)} className="m-1">
                {getFlagByCode(currentLanguage)}
            </button>
            {showDropdown && (
                <div className="absolute right-0 mt-2 py-1 w-40 bg-white text-primary-700 text-md border rounded shadow-xl">
                    {languages.map((lng) => (
                        <div key={lng.code} onClick={() => changeLanguage(lng.code)} className="cursor-pointer px-4 py-2 hover:bg-gray-800 flex items-center">
                            {lng.flag} <span className="ml-2">{lng.name}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default LanguageSwitcher;

import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './Firebase';
import api from './Api'; 

const userContext = createContext();

export const useUser = () => {
  return useContext(userContext);
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userInfo, setUserInfo] = useState({ phone: '', accept_contact: false, user_type: null });
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (isUserLoading) return; // Exit early if signup is in progress
      setUser(user);
      if (user && !isUserLoading) {
        try {
          const userInfoResponse = await api.get('/user'); 
          const userSubscriptionResponse = await api.get('/user/subscription');
          console.log(userInfoResponse);
          console.log(userSubscriptionResponse);
          setUserInfo(userInfoResponse.data);
          setSubscriptionInfo(userSubscriptionResponse.data);
        } catch (err) {
          console.error('Failed to fetch user info or subscription:', err);
          setUserInfo({ phone: '', accept_contact: false });
          setSubscriptionInfo(null);
        }
      } else {
        setUserInfo({});
        setSubscriptionInfo(null);
      }
    });

    return () => unsubscribe();
  }, [isUserLoading]);

  const updateUserType = async (type) => {
      try {
          const updateData = { user_type: type };
          const response = await api.patch('/user', updateData);

          if (response.status === 200) {
              setUserInfo(prevState => ({
                  ...prevState,
                  user_type: type
              }));
              console.log('User type updated:', response.data);
          } else {
              console.error('Update failed with status:', response.status);
          }
      } catch (error) {
          console.error('Failed to update user type:', error);
      }
  };

  return (
    <userContext.Provider value={{ user, setUser, userInfo, setUserInfo, subscriptionInfo, setSubscriptionInfo, isUserLoading, setIsUserLoading, updateUserType }}>
            {children}
    </userContext.Provider>
  );
};

import React, { useState } from 'react';
import { auth } from './Firebase';
import api from './Api';
import { signOut } from "firebase/auth";
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from './assets/logo-text-only-color.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { useUser } from './UserContext';


const Login = () => {
  const { setUser } = useUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


  const googleProvider = new GoogleAuthProvider();
  const microsoftProvider = new OAuthProvider('microsoft.com');

  // delay for login throttling
  const delay = ms => new Promise(res => setTimeout(res, ms));

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Reset error message on new login attempt
    setIsButtonDisabled(true); 
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user); // Update the user state in App.js
      navigate('/');
    } catch (error) {
      await delay(1500);
      setIsButtonDisabled(false);
      if (error.code === 'auth/user-disabled') {
        console.error('Login Error:', error);
        setError(t('login.userDisabled'));
      }
      else {
        console.error('Login Error:', error);
        setError(t('login.error'));
      }
    }
  };


  const handleOAuthLogin = async (provider) => {
    try {
      const result = await signInWithPopup(auth, provider);
      // check whether the user already exists in our db (and has signed up through the signup form)
      const userExists = await checkUserExistsInAppDatabase(result.user.uid);
      if (!userExists) {
        await signOut(auth);
        alert(t('login.signUpFirst'))
        navigate('/signup');
      } else {
        setUser(result.user); // Update the user state in your app
        navigate('/'); // Navigate to the home page or dashboard
      }
    } catch (error) {
      console.error('OAuth Login Error:', error);
      if (error.code === 'auth/account-exists-with-different-credential') {
        setError(t('login.accountExistsWithDifferentCredential'));
      } else {
        setError(t('login.errorOAuth'));
      }
    }
  };


  const checkUserExistsInAppDatabase = async (userId) => {
    try {
      await api.get(`/user`);
      return true; // If the request succeeds, the user exists
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // A 404 response indicates the user does not exist
        return false;
      }
      console.error("Error checking user existence:", error);
      return false;
    }
  };


  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto h-12 w-auto" src={logo} alt="Hurai" />
        <h2 className="mt-10 text-center text-3xl font-extrabold text-gray-900">
          {t('login.title')}
        </h2>

        <form className="mt-8 space-y-6" onSubmit={handleLogin}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              {t('general.email')}
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('general.email')}
              />
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium text-gray-900">
                {t('general.password')}
              </label>
              <div className="text-center">
                <Link to="/forget-password" className="text-sm text-primary-600 hover:text-primary-500">
                  {t('login.forgotPassword')}
                </Link>
              </div>
            </div>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t('general.password')}
              />
            </div>
          </div>
          {error && <div className="mt-2 text-sm text-error">{error}</div>}
          <div>
            <button
              type="submit"
              className="flex justify-center w-full rounded-md border border-transparent btn-main py-2 px-4 text-sm font-medium text-white shadow-smfocus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            >
              {t('general.login')}
            </button>
          </div>

          <div className="mt-6 grid grid-cols-2 gap-3">
            <div>
              <button
                onClick={() => handleOAuthLogin(googleProvider)}
                className="w-full justify-center rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <div className='flex items-center h-10'>
                  <FontAwesomeIcon icon={faGoogle} />
                  <div className='ml-2'>
                    {t('login.withGoogle')}
                  </div>
                </div>
              </button>
            </div>
            <div>
              <button
                onClick={() => handleOAuthLogin(microsoftProvider)}
                disabled={isButtonDisabled}
                className="w-full justify-center rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <div className='flex items-center h-10'>
                  <FontAwesomeIcon icon={faMicrosoft} />
                  <div className='ml-2'>
                    {t('login.withMicrosoft')}
                  </div>
                </div>
              </button>
            </div>
          </div>

          <p className="mt-2">
            {t('login.noAccount')}{' '}
            <Link to="/signup" className="font-medium text-primary-600 hover:text-primary-500">
              {t('login.signupNow')}
            </Link>
          </p>
        </form>

      </div>
    </div>
  );
};

export default Login;

// useChatbot.js

import { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { baseUrl } from './Api'
import jsPDF from 'jspdf';
import { getDocument } from 'pdfjs-dist/legacy/build/pdf';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import { getAuth } from 'firebase/auth';
import api from './Api';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import UpgradeModal from './UpgradeModal';
import ReactMarkdown from 'react-markdown';
class FatalError extends Error { }




function Chatbot({ messages, setMessages, documents, selectedLaws, modelVersion, user_type }) {
    const { t } = useTranslation();
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const suggestedQuestions = t('chatbot.suggestedQuestions', { returnObjects: true });
    const [input, setInput] = useState('');
    const [isProcessing, setIsProcessing] = useState(false); // New state to track message processing
    const chatContainerRef = useRef(null);
    const [isDocumentProcessing, setIsDocumentProcessing] = useState(false);
    const textareaRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);  // State to control the visibility of the UpgradeModal
    const [upgradeMessage, setUpgradeMessage] = useState('');
    const [dynamicSuggestedQuestions, setDynamicSuggestedQuestions] = useState([]);

    useEffect(() => {
        const adjustHeight = () => {
            const textarea = textareaRef.current;
            if (textarea) {
                if (!input.trim()) {
                    // If input is essentially empty, force a smaller starting height
                    textarea.style.height = '20px';  // Set to minimal height
                } else {
                    textarea.style.height = 'auto';  // Reset height to recalculate
                    const maxHeight = 200;  // Set the maximum height
                    const scrollHeight = textarea.scrollHeight;
                    if (scrollHeight > maxHeight) {
                        textarea.style.height = `${maxHeight}px`;
                        textarea.style.overflowY = 'auto';  // Enable scrolling
                    } else {
                        textarea.style.height = `${scrollHeight}px`;
                        textarea.style.overflowY = 'hidden';  // Hide scrolling when not needed
                    }
                }
            }
        };

        adjustHeight();
    }, [input]);

    useEffect(() => {
        // Scroll to the bottom of the chat container whenever messages update
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        const anyProcessing = documents.some(doc => !doc.isProcessed);
        setIsDocumentProcessing(anyProcessing);
    }, [documents]);

    const fetchSuggestedQuestions = async (updatedMessages) => {
        const maxRetries = 3;
        let retries = 0;
        const retryFetch = async () => {
            try {
                const response = await api.post('/suggestedQuestions', {
                    messages: updatedMessages
                });
                const data = response.data.suggestedQuestions;
                if (data && data.follow_up_questions) {
                    setDynamicSuggestedQuestions(data.follow_up_questions);
                } else {
                    if (retries < maxRetries) {
                        retries++;
                        await retryFetch();
                    } else {
                        setDynamicSuggestedQuestions([]);
                    }
                }
            } catch (error) {
                console.error('Error fetching suggested questions:', error);
                setDynamicSuggestedQuestions([]); 
            }
        };
        await retryFetch();
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!input.trim() || isProcessing || isDocumentProcessing) return;

        // set currentQuestion to null to remove the second set of suggested questions from the chat once the user asks a question
        setCurrentQuestion(null);
        setDynamicSuggestedQuestions([]);

        // Count the number of user messages
        const userMessageCount = messages.filter(message => message.role === "user").length;

        const userMessage = { content: input, role: "user" };

        if (userMessageCount >= 9) {
            // If 9 questions are already asked, add a debug_system message
            const limitMessage = {
                content: t('chatbot.maximumMessagesReached'),
                role: 'assistant'
            };
            setMessages(prevMessages => [...prevMessages, limitMessage]);
        } else {
            // Normal message handling
            setIsProcessing(true);
            setMessages(prevMessages => [...prevMessages, userMessage]);
            setInput('');
            try {
                sendMessagesBackend([...messages, userMessage]);
            } catch (error) {
                console.error('Error in sending messages:', error);
            } finally {
            }
        }
    };

    const handleSuggestedQuestionClick = async (question) => {

        if (isProcessing || isDocumentProcessing) return;

        const userMessageCount = messages.filter(message => message.role === "user").length;

        const userMessage = { content: question, role: "user" };
        
        if (userMessageCount >= 5) {
            // If 5 questions are already asked, add a debug_system message
            const limitMessage = {
                content: t('chatbot.maximumMessagesReached'),
                role: 'assistant'
            };
            setMessages(prevMessages => [...prevMessages, limitMessage]);
        }
        else {
            setIsProcessing(true);
            setMessages(prevMessages => [...prevMessages, userMessage]);
            try {
                sendMessagesBackend([...messages, userMessage]);
            } catch (error) {
                console.error('Error in sending messages:', error);
            } finally {
            }
        }
    };

    const sendMessagesBackend = async (parsed_messages) => {
        // console.log("Parsed messages:" + JSON.stringify(parsed_messages));
        
        setDynamicSuggestedQuestions([]);

        const currentLanguage = i18n.language;

        const documentsData = documents.filter(doc => doc.isProcessed).map(doc => {
            return {
                chunks: doc.chunks.map(chunk => ({
                    text: chunk.text,
                    embedding: chunk.embedding,
                    id: chunk.id,
                    metadata: chunk.metadata
                }))
            };
        });

        console.log("logging model version handleSuggestedQuestionClick: " + modelVersion)

        const dataToSend = {
            messages: parsed_messages,
            documents: documentsData,
            language: currentLanguage,
            selectedLaws: selectedLaws,
            user_type: user_type,
            modelVersion: modelVersion,
        };

        console.log("dataToSend: " + JSON.stringify(dataToSend));

        try {
            // Convert data to JSON and calculate its size
            // const jsonData = JSON.stringify(dataToSend);
            // const bytes = new TextEncoder().encode(jsonData).length;
            // const kilobytes = bytes / 1024;
            // console.log(`Size of request being sent: ${kilobytes.toFixed(2)} KB`);

            const auth = getAuth();
            const authToken = auth.currentUser ? await auth.currentUser.getIdToken() : '';
            const serverBaseURL = baseUrl; // Make sure this is defined as your API's base URL
            const streamUrl = `${serverBaseURL}/appendMessage`;

            let messageContent = ""

            await fetchEventSource(streamUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "text/event-stream",
                    Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
                },
                openWhenHidden: true,
                body: JSON.stringify(dataToSend),
                onopen(res) {
                    setIsProcessing(true);
                    if (res.ok && res.status === 200) {
                        //add placeholder assistantMessage to messages array
                        const assistantMessage = { 'role': 'assistant', 'content': '' }
                        setMessages(prevMessages => [...prevMessages, assistantMessage]);
                    } else {
                        const debugMessage = { 'role': 'debug_sustem', 'content': t('chatbot.internalServerErrorMessage') }
                        setMessages(prevMessages => [...prevMessages, debugMessage]);
                    }
                },
                onmessage(event) {
                    // console.log("Received message", event.data);
                    if (event.data.trim() === "DONE") {
                        // Handle the "DONE" message, if you need to perform any final actions
                        // console.log("last message received.");
                    } else if (event.data.includes("VALIDATIONERROR:")) {
                        const decodedMessage = event.data.replace(/<br>/g, "\n").replace("VALIDATIONERROR:", "");
                        if (!decodedMessage.includes('Neem contact op') && !decodedMessage.includes('Please contact')) { //in case of reason numberOfQuestion too large don't show upgrade.
                            const newUpgradeMessage = decodedMessage + " " + t('profile.upgradeToContinue');
                            setUpgradeMessage(newUpgradeMessage);
                            setShowUpgradeModal(true);
                        }
                        //set messages to: remove last message (placeholder assistantMessage) and create debug_system message with response
                        setMessages(prevMessages => {
                            const updatedMessages = prevMessages.slice(0, -1); // Remove last message (placeholder assistantMessage)
                            updatedMessages.push({
                                role: 'debug_system',
                                content: decodedMessage
                            });
                            return updatedMessages;
                        });
                    } else {
                        // For each non-"DONE" message, append it immediately to the assistant's current content
                        const decodedMessage = event.data.replace(/<br>/g, "\n"); // decode <br> back to newlines
                        messageContent += decodedMessage;
                        setMessages(prevMessages => {
                            const updatedMessages = [...prevMessages];
                            const lastIndex = updatedMessages.length - 1; // Assuming the last message is the assistant message
                            // Append the new content with a newline or space as needed
                            updatedMessages[lastIndex].content = messageContent;
                            return updatedMessages;
                        });
                    }
                },
                onerror(err) {
                    setIsProcessing(false);
                    if (err instanceof FatalError) {
                        console.log("Error from server", err);
                        throw err; // rethrow to stop the operation
                    } else {
                        console.log("Error from server", err);
                        throw (Error(err))
                    }
                },
                onclose() {
                    console.log("Connection closed by the server");
                    setIsProcessing(false);
                    // following setMessages ensures fetchSuggestedQuestions is only run with updated messages
                    setMessages(prevMessages => {
                        const updatedMessages = [...prevMessages];
                        fetchSuggestedQuestions(updatedMessages); 
                        return updatedMessages;
                    });
                },
            });

        } catch (errorResponse) {
            let errorMessage = t('chatbot.internalServerErrorMessage'); // Default error message
            let detailedMessage = '';

            // Check if the error is an instance of an AxiosError
            if (errorResponse && errorResponse.response) {
                const errorData = errorResponse.response.data;
                const errorStatus = errorResponse.response.status;
                console.log(`Error Status: ${errorStatus}`);
                detailedMessage = errorData.detail || errorMessage;
            } else {
                // For non-Axios errors (e.g., network issues), log the error and use the default message
                console.error("Unexpected error:", errorResponse);
                detailedMessage = errorMessage;
            }

            if (window.location.hostname.includes("app-test")) {
                // If in the 'app-test' environment, append additional error details if available
                errorMessage = `API call failed. Error message: ${detailedMessage}`;
            }

            // console.error("Final error message:", errorMessage);
            // console.log("API failed, creating response data:" + JSON.stringify(parsed_messages));
            const debugMessage = { 'role': 'debug_system', 'content': errorMessage }
            setMessages(prevMessages => [...prevMessages, debugMessage]);
        }
    };


    const downloadChat = () => {
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: 'a4'
        });
        //base messages as base64 string to retrieve later
        const base64Messages = btoa(unescape(encodeURIComponent(JSON.stringify(messages))));
        pdf.text('HiddenText', 0, 0); // Invisible text
        pdf.link(0, 0, 10, 10, { url: `data:text/plain;base64,${base64Messages}` });


        // pdf layout config
        pdf.setFillColor('#ededed');
        pdf.rect(0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height, 'F');
        pdf.setFont('helvetica'); // This is an example, replace with 'Noto Serif TC' if available
        const pageHeight = pdf.internal.pageSize.height; // Height of the PDF page
        const lineHeight = 8; // Adjust as needed
        const maxLineLength = 480; // Maximum line length in characters
        const headerHeight = 15; // Height of the header
        const chatMargin = 50
        const fontSize = 7
        // Get the current date in European format (day-month-year)
        const currentDate = new Date().toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });

        // Print chat UUID and download date
        pdf.setTextColor('#000000');
        pdf.setFontSize(10);
        let infoYPosition = 20; // Starting Y position for the info

        pdf.text(`Chat UUID: ${uuidv4()}`, 20, infoYPosition); // Using uuidv4() to generate a UUID, replace as needed
        infoYPosition += 10;
        pdf.text(`Download Date: ${currentDate}`, 20, infoYPosition);
        infoYPosition += 20; // Add some space before starting the messages
        if (documents.length > 0) {
            pdf.text('Used documents:', 20, infoYPosition)
            infoYPosition += 15;
            //print the names of the documents
            documents.forEach(doc => {
                pdf.text(doc.filename, 20, infoYPosition);
                infoYPosition += 10;
            });
        }

        let yPosition = infoYPosition; // Start messages after the general info

        const printHeaders = () => {
            // Set header style
            pdf.setFillColor('#000000'); // Black background
            pdf.setTextColor('#FFFFFF'); // White text
            pdf.setFontSize(fontSize);

            // Draw background for headers
            pdf.rect(20, yPosition, 50, headerHeight, 'F'); // Background for 'Role'
            pdf.rect(70, yPosition, pdf.internal.pageSize.width - 90, headerHeight, 'F'); // Background for 'Message'

            // Print header text
            pdf.text(t('chatbot.role'), 25, yPosition + 11);
            pdf.text(t('chatbot.message'), 75, yPosition + 11);

            yPosition += headerHeight + 0.5; // Adjust vertical position after headers
        };

        printHeaders();

        messages.forEach((message, index) => {
            let leftMargin, rightMargin;
            leftMargin = 20;
            rightMargin = 20
            const bgColor = message.role === "user" ? "#f0f0f0" : (message.role === "assistant" ? "#d0e0f0" : "#ff4d4d");
            const textColor = message.role === "user" ? "#000000" : "#000000";

            let lines = pdf.splitTextToSize(message.content, maxLineLength); // Split the text into lines

            lines.forEach((line, lineIndex) => {

                // Check if the line fits on the page, if not, add a new page
                if (yPosition + lineHeight > pageHeight - 20) {
                    pdf.addPage();
                    yPosition = 20; // Reset the vertical position for the new page
                    printHeaders();
                }

                // Set the background for each line
                pdf.setFillColor(bgColor);
                pdf.rect(leftMargin, yPosition, pdf.internal.pageSize.width - rightMargin - leftMargin, lineHeight + 8, 'F');

                // Add the text line
                pdf.setTextColor(textColor);
                pdf.setFontSize(fontSize);
                if (lineIndex === 0) {
                    // Print the role only for the first line of each message
                    pdf.text(message.role, 25, yPosition + lineHeight);
                }
                pdf.text(line, leftMargin + chatMargin + 10, yPosition + lineHeight);

                // Move to the next line position
                yPosition += lineHeight + 5;
            });

        });

        pdf.setDocumentProperties({
            title: 'Chat Transcript',
            subject: 'Chat history',
            author: 'Chatbot',
            keywords: 'chat, transcript',
            creator: 'Chatbot'
        });

        pdf.save(`${uuidv4()}.pdf`);
    };

    // Drag and Drop Functions
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            readChatFile(file);
        }
    };

    const readChatFile = (file) => {
        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
            try {
                const typedArray = new Uint8Array(e.target.result);
                const pdf = await getDocument(typedArray).promise;
                const firstPage = await pdf.getPage(1);

                let base64Messages = null;
                const annotations = await firstPage.getAnnotations();
                for (const annotation of annotations) {
                    if (annotation.unsafeUrl && annotation.unsafeUrl.startsWith('data:text/plain;base64,')) {
                        base64Messages = annotation.unsafeUrl.split('data:text/plain;base64,')[1];
                        break;
                    }
                }

                if (base64Messages) {
                    const decodedMessages = JSON.parse(decodeURIComponent(escape(atob(base64Messages))));
                    setMessages(decodedMessages);
                } else {
                    console.error('No messages found in the PDF');
                }
            } catch (error) {
                console.error('Error reading chat from PDF:', error);
            }
        };

        fileReader.onerror = (error) => {
            console.error('Error reading file:', error);
        };

        fileReader.readAsArrayBuffer(file);
    };

    // Function to determine button classes
    const getButtonClasses = () => {
        let classes = "";
        if (isProcessing || isDocumentProcessing) {
            classes += "text-white font-medium bg-primary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-not-allowed ";
        } else {
            classes += "text-white font-medium bg-primary-700 hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 ";
        }
        return classes;
    };

    // Function to get the tooltip message
    const getTooltipMessage = () => {
        if (isProcessing || isDocumentProcessing) {
            return t('chatbot.processingDocMessage');
        }
        return "";
    };

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const handleSuggestedQuestion = (question, answer) => {

        const userMessage = { content: question, role: "user" };
        setMessages(prevMessages => [...prevMessages, userMessage]);
        // Simulate typing delay
        setIsProcessing(true);
        setTimeout(() => {
            const assistantMessage = { content: answer, role: "assistant" };
            setMessages(prevMessages => [...prevMessages, assistantMessage]);
            setIsProcessing(false);
            // Find the question object from i18n or a local state where questions are stored
            const questionObj = suggestedQuestions.find(q => q.question === question);
            setCurrentQuestion(questionObj); // Set the current question for follow-up actions
        }, 2000);
    };

    const handleFollowUpAction = (action) => {
        if (action === 'letter') {
            setIsProcessing(true); // Show processing/loading indicator
            setTimeout(() => {
                const userMessage = { content: t('chatbot.letter_action'), role: "user" };
                const followUpMessage = {
                    content: currentQuestion.followup_letter,
                    role: 'assistant'
                };
                setMessages(prevMessages => [...prevMessages, userMessage, followUpMessage]);
                setCurrentQuestion(null);
                setIsProcessing(false);
            }, 2000);
        } else if (action === 'ask') {
            // The text box focus will be set immediately after the delay
            document.getElementById("chatInput").focus();
            setCurrentQuestion(null);
        }

    };




    return (
        <div className="relative max-w-4xl mx-auto border border-border rounded-lg p-3 bg-white shadow-lg">
            <span
                className="absolute z-10 font-sans inline-flex items-center rounded-md bg-copy-50 px-2 py-1 text-xs font-medium text-copy-400 ring-1 ring-inset ring-secondary-300 transform"
                title="The chatbot is currently in beta version"
            >
                Beta v0.12
            </span>
            <div
                ref={chatContainerRef}
                className="h-[70vh] overflow-y-auto p-2 mt-8"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                {messages.length === 0 && (
                    <>
                        <input
                            type="file"
                            onChange={(e) => readChatFile(e.target.files[0])}
                            style={{ display: 'none' }}
                            id="fileUpload"
                        />
                        <div className='mt-[30vh]'>
                            {t('chatbot.initialInstructions', { returnObjects: true }).map((instruction, index) => (
                                <label key={index} htmlFor="fileUpload" className="mt-[1vh] block text-center text-gray-400">{instruction}</label>
                            ))}
                        </div>
                    </>
                )}
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`relative my-2 p-2 rounded leading-6 
                                    ${message.role === "user" ? 'bg-primary-700 text-copy-100 chatbot-right whitespace-pre-line shadow-md' :
                                        message.role === "assistant" ? 'bg-copy-50 text-copy-900 chatbot-left whitespace-pre-line shadow-md' :
                                            'bg-error-content chatbot-left text-copy-100 whitespace-pre-line shadow-md'}`}
                    >
                        <ReactMarkdown
                            components={{
                                pre: ({ node, ...props }) => (
                                    <div className="overflow-x-auto">
                                        <pre {...props} className="whitespace-pre-wrap bg-gray-100 p-2 rounded-md" />
                                    </div>
                                ),
                                code: ({ node, inline, className, children, ...props }) => {
                                    return !inline ? (
                                        <code
                                            {...props}
                                            className={`block bg-gray-100 p-2 rounded-md overflow-x-auto ${className}`}
                                        >
                                            {children}
                                        </code>
                                    ) : (
                                        <code {...props} className="bg-gray-100 p-1 rounded">
                                            {children}
                                        </code>
                                    );
                                },
                                a: ({ node, ...props }) => (
                                    <a {...props} target="_blank" rel="noopener noreferrer" />
                                ),
                            }}
                        >
                            {message.content}
                        </ReactMarkdown>


                        {message.role !== "user" && (
                            //render a copy to clipboard button for the assistant message
                            <button
                                onClick={() => copyToClipboard(message.content)}
                                title="Copy to clipboard"
                                className="absolute -bottom-1.5 -right-1.5 inline-flex items-center justify-center w-5 h-5 bg-secondary-100 rounded shadow-lg cursor-pointer hover:bg-secondary-200"
                                style={{ outline: 'none' }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-copy-400">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
                                </svg>
                            </button>
                        )}
                    </div>
                ))}

                {/* This is the workflow for the hardcoded suggested messages */}
                {messages.every(message => message.role === "assistant") &&
                    //first part for the fixed message workflow. 
                    <div className="relative md:pr-32">
                        <div className='relative min-w-full mt-10 chatbot-right pt-6 mr-2 whitespace-pre-line shadow-md my-2 p-2 rounded md:leading-6 bg-gray-100'>
                            <p className=' ml-2 mb-4 md:text-base text-sm text-left italic'>
                                 {t('chatbot.suggestedQuestionsTitle')}
                            </p>
                            {suggestedQuestions.map((item, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleSuggestedQuestion(item.question, item.answer)}
                                    className="relative min-w-full bg-primary-700 text-copy-100 hover:text-copy-50 hover:bg-primary-800 mr-2 text-right whitespace-pre-line shadow-md my-2 p-2 rounded leading-6 "
                                >
                                    {item.question}
                                </button>
                            ))}
                        </div>
                    </div>
                }
                {currentQuestion && (
                    //second part for the fixed message workflow for the follow up actions 
                    <div className="relative md:pr-32">
                        <div className='relative min-w-full mt-10 chatbot-right pt-6 mr-2 whitespace-pre-line shadow-md my-2 p-2 rounded md:leading-6 bg-gray-100'>
                            <p className=' ml-2 mb-4 md:text-base text-sm italic'>
                                 {t('chatbot.suggestedActionsTitle')}
                            </p>
                        <button onClick={() => handleFollowUpAction('letter')} className="min-w-full chatbot-right bg-primary-700 text-copy-100 hover:text-copy-50 hover:bg-primary-800 px-4 py-2 text-right whitespace-pre-line shadow-md rounded-md border border-gray-300 m-1">
                            {t('chatbot.letter_action')}
                        </button>
                        <button onClick={() => handleFollowUpAction('ask')} className="min-w-full bg-primary-700 text-copy-100 hover:text-copy-50 hover:bg-primary-800 px-4 py-2 text-right whitespace-pre-line shadow-md rounded-md border border-gray-300 m-1">
                            {t('chatbot.question_action')}
                        </button>
                        </div>
                    </div>
                )}


                {/* This is the workflow for the generated suggested messages */}
                {(dynamicSuggestedQuestions.length > 0 && !isProcessing) && (
                    <div className="relative md:pr-32">
                        <div className='relative chatbot-right min-w-full md:mt-10 mt-4 md:ml-32 pt-6 mr-2 whitespace-pre-line shadow-md my-2 p-2 rounded md:leading-6 bg-gray-100'>
                            <p className=' ml-2 mb-4 md:text-base text-sm text-left italic'>
                                 {t('chatbot.suggestedQuestionsTitle')}
                            </p>
                            {dynamicSuggestedQuestions.map((question, index) => (
                                <button key={index} onClick={() => handleSuggestedQuestionClick(question)} className="relative min-w-full bg-primary-700 text-copy-100 hover:text-copy-50 hover:bg-primary-800 text-right whitespace-pre-line shadow-md my-2 p-2 rounded leading-6">
                                    {question}
                                </button>
                            ))}
                        </div>
                    </div>
                )}

            </div>
            <form className="flex mt-4 items-center" onSubmit={handleSubmit}>

                <div className={`relative flex items-center w-full rounded-lg border-2 ${isFocused ? 'border-blue-500' : 'border-gray-300'}`}>
                    <textarea
                        id="chatInput"
                        ref={textareaRef}
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder={t('chatbot.placeholder')}
                        className="flex-1 p-2 m-1 min-h-[40px] max-h-[100px] overflow-y-auto w-full bg-gray-50 focus:border-transparent focus:ring-0 border-transparent focus:border-none border-none overflow-hidden resize-none"
                        disabled={isProcessing}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {  
                                e.preventDefault();  
                                if (!isProcessing) {
                                    handleSubmit(e); 
                                }
                            }
                        }}
                    />
                    <button
                        type="submit"
                        disabled={isProcessing}
                        className={`${getButtonClasses()} mt-2 ml-2 mb-1 mr-1 px-2 h-10 w-10 rounded-lg flex items-center justify-center`}
                        title={getTooltipMessage()}
                    >
                        {isProcessing ? (
                            <svg className="animate-spin h-5 w-5 text-primary-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="currentColor" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"/>
                            </svg>
                        )}
                    </button>
                </div>

                {messages.length > 0 ? (
                    <button onClick={downloadChat} className="ml-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#007bff" className="w-6 h-6 stroke-primary-800 hover:stroke-primary-700">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m9 13.5 3 3m0 0 3-3m-3 3v-6m1.06-4.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
                        </svg>
                    </button>
                ) : (
                    <div className="ml-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" className="w-6 h-6 stroke-primary-100">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m9 13.5 3 3m0 0 3-3m-3 3v-6m1.06-4.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
                        </svg>
                    </div>
                )}
            </form>


            {showUpgradeModal && (
                <UpgradeModal
                    onClose={() => setShowUpgradeModal(false)}
                    upgradeMessage={upgradeMessage}
                />
            )}



        </div>
    );
};

export default Chatbot;

import React, { Fragment } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, NavLink } from 'react-router-dom';
import ChatbotPage from './ChatbotPage';
import ForgetPassword from './ForgetPassword';
import Login from './Login';
import Signup from './Signup';
import Profile from './Profile';
import MoreQuestions from './MoreQuestions';
import RentalCheckPage from './RentalCheckPage';
// import CompanyMission from './CompanyMission';
// import FAQPage from './FAQPage';
// import TeamPage from './TeamPage';
// import ContactPage from './ContactPage';
import Footer from './Footer'
import LandingPage from './LandingPage';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher'
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import logo from './assets/logo-dark.svg'
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import { StripeComponent, Return, CheckoutForm } from './Stripe';
import { useUser } from './UserContext';
import NotFound from './NotFound';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function App() {

  const { t } = useTranslation();
  const { user, subscriptionInfo } = useUser();
  const navigation = [
    { name: t('navbar.rentalCheck'), href: '/rental-check', current: false },
    { name: t('navbar.chat'), href: '/', current: false },
    { name: t('navbar.nogSteedsVragen'), href: '/more-questions', current: false },
    // { name: t('navbar.mission'), href: '/mission', current: false },
    // { name: t('navbar.faq'), href: '/faq', current: false },
    // { name: t('navbar.team'), href: '/team', current: false },
    // { name: t('navbar.contact'), href: '/getintouch', current: false },
  ];

  const userNavigation = user ? [
    { name: t('navbar.profile'), href: '/profile' },
  ] : [
    { name: t('general.login'), href: '/login' },
  ];


  return (
    <Router>
      <div className="min-h-full">
      <Disclosure as="nav" className="bg-white shadow-2xl border-b-2 border-copy-300">
          {({ open }) => (
            <>
              <div className="mx-auto px-10 sm:px-14 lg:px-20">
                <div className="flex h-28 items-center justify-between">
                  
                  {/* Left - Logo Section */}
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <NavLink to="https://hurai.nl">
                        <div className="flex items-center">
                          <img
                            className="h-8 w-auto mr-2"
                            src={logo}
                            alt="Hurai"
                          />
                        </div>
                      </NavLink>
                    </div>
                  </div>

                  {/* Center - Navigation Menu Section */}
                  <div className="hidden md:flex flex-grow justify-center"> {/* flex-grow centers the nav items */}
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          className={({ isActive }) =>
                            classNames(
                              isActive
                                ? 'border-b-2 border-primary-600 text-primary-900' 
                                : 'text-neutral-700 hover:text-primary-600',      
                              'px-3 py-2 text-sm font-medium'
                            )
                          }
                          end
                        >
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                  </div>

                  {/* Right - Upgrade, Language Switcher, and User Menu Section */}
                  <div className="hidden md:flex items-center">
                  {user && (!subscriptionInfo || !subscriptionInfo.isActive) ? (
                      <NavLink
                        to="/upgrade"
                        className="m-4 px-4 py-2 border-b-2 border-transparent text-secondary-600 hover:text-secondary-700 hover:border-secondary-700"
                      >
                        Upgrade
                      </NavLink>
                    ) : null}

                    <div className="mr-4 mt-1 z-50">
                      <LanguageSwitcher />
                    </div>

                    {user ? (
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="relative flex max-w-xs items-center focus:outline-none focus:ring-2 focus:ring-primary-600">
                            <span className="sr-only">Open user menu</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1"
                              stroke="currentColor"
                              className="w-10 h-10 stroke-copy-600 hover:stroke-primary-800"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                              />
                            </svg>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <Link
                                    to={item.href}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                  >
                                    {item.name}
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    ) : (
                      <NavLink to="/login" className="flex items-center text-neutral-700 hover:text-primary-600 mr-4">
                        <p>{t('general.login')}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mt-1 ml-2">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                        </svg>
                      </NavLink>
                    )}
                  </div>

                  {/* Mobile Menu Button */}
                  <div className="-mr-2 flex md:hidden">
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-primary-600">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-8 w-8 stroke-copy-600 hover:stroke-primary-800" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-8 w-8 stroke-copy-600 hover:stroke-primary-800" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              {/* Mobile Menu Panel */}
              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as={Link}
                      to={item.href}
                      className={({ isActive }) =>
                        classNames(
                          isActive
                            ? 'border-b-2 border-primary-600 text-primary-900' // Active state
                            : 'text-neutral-700 hover:text-primary-600', // Hover state
                          'block rounded-md px-3 py-2 text-base font-medium'
                        )
                      }
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>

                <div className="border-t border-gray-200"></div>

                <div className=" px-3 py-2 mr-4 mt-1 z-50">
                  <LanguageSwitcher />
                </div>

                {user && (!subscriptionInfo || !subscriptionInfo.isActive) ? (
                  <Disclosure.Button
                    as={Link}
                    to="/upgrade"
                    className="block w-full text-center px-3 py-2 border-b-2 border-transparent text-secondary-600 hover:text-secondary-700 hover:border-secondary-700"
                  >
                    Upgrade
                  </Disclosure.Button>
                ) : null}

                {userNavigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as={NavLink}
                    to={item.href}
                    className={({ isActive }) =>
                      classNames(
                        isActive ? 'border-b-2 border-primary-600 text-primary-900' : 'text-neutral-700 hover:text-primary-600',
                        'block px-4 py-2 rounded-md text-base font-medium'
                      )
                    }
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </Disclosure.Panel>

            </>
          )}
        </Disclosure>

        <div className="bg-white min-h-[80vh]">
          <div className="p-4">
            <Routes>
              {/* <Route path="/mission" element={<CompanyMission />} /> */}
              {/* <Route path="/faq" element={<FAQPage />} /> */}
              {/* <Route path="/team" element={<TeamPage />} /> */}
              {/* <Route path="/getintouch" element={<ContactPage />} /> */}
              <Route path="/rental-check" element={<RentalCheckPage />} />
              <Route path="/more-questions" element={<MoreQuestions />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forget-password" element={<ForgetPassword />} />
              <Route path="/terms" element={<TermsAndConditions />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              {user ? (
                <>
                  <Route path="/" element={<ChatbotPage />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/upgrade" element={<StripeComponent />} />
                  <Route path="/checkout/:planId" element={<CheckoutForm />} />
                  <Route path="/return" element={<Return />} />
                </>
              ) : (
                <>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/signup" element={<Signup />} />
                </>
              )}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>


        <Footer />
      </div>
    </Router>
  );
}

export default App;
import React from 'react';
import { Link } from 'react-router-dom';
import logo from './assets/logo-text-only-color-question.svg';

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      <img src={logo} alt="Logo" className="mb-8 w-64" />
      <h1 className="text-6xl font-bold text-gray-800 mb-4">404</h1>
      <p className="text-2xl text-gray-600 mb-8">Oops! The page you're looking for doesn't exist.</p>
      <Link to="/" className="px-6 py-3 bg-primary-600 text-white text-lg rounded-md hover:bg-primary-700">
        Go Back Home
      </Link>
    </div>
  );
};

export default NotFound; 
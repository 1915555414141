import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function PrivacyPolicy() {
  const { i18n } = useTranslation();
  const [privacyHtml, setTermsHtml] = useState('');

  useEffect(() => {
    const loadTerms = async () => {
      try {
        const termsModule = await import(`./assets/privacy_${i18n.language}.js`);
        setTermsHtml(termsModule.default);
      } catch (error) {
        console.error('Error loading the privacy policy:', error);
        // Handle the error, e.g., load a fallback language or show an error message
      }
    };

    loadTerms();
  }, [i18n.language]);

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: privacyHtml }} />
    </div>
  );
}

export default PrivacyPolicy;
